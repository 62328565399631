import Vue from 'vue'
import VueRouter from 'vue-router'
import AppManagerChat from 'Components/components/AppChat/AppManagersChat.vue'
import CpaStatistics from 'Components/components/CpaStatistics.vue'
import CpaStatisticsBeta from 'Components/components/CpaStatisticsBeta.vue'
import PersonalStarter from 'Components/pages/personal-starter/PersonalPage.vue'
import CpaStat from 'Components/components/CpaStat.vue'
import CpaOffers from 'Components/components/CpaOffers.vue'
import FastProductsList from 'Components/components/FastProductsList.vue'
import FastProductsItem from 'Components/components/FastProductsItem.vue'
import EasyMoney from 'Components/components/EasyMoney.vue'
import UserPathActivation from 'Components/Components/UserPathActivation.vue'
import UserPathActivationEdit from 'Components/Components/UserPathActivationEdit.vue'
import AdvisingStudentPage from 'Components/components/AdvisingStudentPage.vue'
import AdvisingStudentCuratorsList from 'Components/components/AdvisingStudentCuratorsList.vue'
import AdvisingStudentCurator from 'Components/components/AdvisingStudentCurator.vue'
import AdvisingCuratorPage from 'Components/components/AdvisingCuratorPage.vue'
import AdvisingCuratorStatistics from 'Components/components/AdvisingCuratorStatistics.vue'
import AdvisingCuratorRequestsList from 'Components/components/AdvisingCuratorRequestsList.vue'
import AdvisingCuratorRejectionsList from 'Components/components/AdvisingCuratorRejectionsList.vue'

Vue.use(VueRouter)

export const router = new VueRouter({
    mode: 'history',
    routes: [
        { name: 'partners', path: '/partners' },
        { name: 'fast-products.list', path: '/spec', component: FastProductsList },
        { name: 'starter.personal', path: '/starter/personal/:alias', component: PersonalStarter },
        { name: 'fast-products.single', path: '/spec/:alias', component: FastProductsItem },
        { name: 'personal-manager', path: '/messaging/personal-manager', component: AppManagerChat },
        { name: 'easy-money', path: '/easy-money', component: EasyMoney },
        { name: 'cpa.statistics', path: '/cpa/personal/statistics', component: CpaStatistics },
        { name: 'cpa.statistics.beta', path: '/cpa/personal/beta', component: CpaStatisticsBeta },
        { name: 'cpa.stat', path: '/cpa/personal/stat', component: CpaStat },
        { name: 'cpa.offers', path: '/cpa/personal/offers', component: CpaOffers  },
        { name: 'cpa.special-offers', path: '/cpa/personal/special-offers', component: CpaOffers },
        { name: 'topic', path: '/publications/:blog/:topic.html' },
        { name: 'zvonobot', path: '/admin/zvonobot/settings/:productId' },
        { name: 'external-page', path: '/id:userId.:specialityId/spec/:alias/offer' },
        { name: 'user-path', path: '/admin/user-path/activation/', component: UserPathActivation },
        { name: 'user-path.edit', path: '/admin/user-path/activation/edit/', component: UserPathActivationEdit },
        {
            name: 'advising.curator',
            path: '/advising/curator',
            component: AdvisingCuratorPage,
            redirect: { name: 'advising.curator.statistics' },
            children: [
                {
                    name: 'advising.curator.statistics',
                    path: 'statistics',
                    component: AdvisingCuratorStatistics
                },
                {
                    name: 'advising.curator.requests',
                    path: 'sended-bids',
                    component: AdvisingCuratorRequestsList
                },
                {
                    name: 'advising.curator.rejections',
                    path: 'rejected-bids',
                    component: AdvisingCuratorRejectionsList
                }
            ]
        },
        {
            name: 'advising.student',
            path: '/advising/student',
            component: AdvisingStudentPage,
            redirect: { name: 'advising.student.curators' },
            children: [
                {
                    name: 'advising.student.curators',
                    path: 'curators',
                    component: AdvisingStudentCuratorsList
                },
                {
                    name: 'advising.student.curators.curator',
                    path: 'curators/:id',
                    component: AdvisingStudentCurator
                }
            ]
        },
    ]
})
